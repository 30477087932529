/* Global CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* Full-width slider container without extra whitespace */
/* Full-width slider container */
.fullwidth-slider-container {
  background: linear-gradient(to bottom, #002f6c, #000000); /* Blue to black gradient */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
  width: 100vw; /* Full viewport width */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* To position the button */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

/* Adjust slider width */
.fullwidth-slider {
  width: 100%; /* Make slider full width */
}

/* Sign Up/In button styles */
.signup-button {
  position: absolute; /* Position it over the slider */
  top: 20px; /* Adjust vertical position */
  right: 20px; /* Adjust horizontal position */
  padding: 10px 20px;
  background-color: #32CD32; /* Button background */
  border: none; /* Remove border */
  color: #fff; /* Text color */
  font-size: 16px; /* Text size */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s; /* Smooth hover effect */
}

.signup-button:hover {
  background-color: #28a745; /* Darken on hover */
}



/* Hero Section */
.hero-section {
  background-color: #f8f9fa;
  padding: 100px 0;
  position: relative;
  min-height: 40vh; /* Ensure minimum height for small screens */
  width: 100%;
  background-image: 'url(Headers/header-bg.jpg)'; /* Ensure correct path */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
}

.hero-title {
  font-size: 3rem;
  font-weight: 700;
  color: #333;
}

.hero-subtitle {
  font-size: 1.25rem;
  margin-bottom: 30px;
  color: #666;
}

.cta-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 30px;
  font-size: 1.1rem;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #0056b3;
}

/* Brand Logos */
.brand-logos {
  background-color: #fff;
  padding: 50px 0;
}

.brand-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px; /* Adjust to desired size */
  width: 150px; /* Adjust to desired size */
  font-size: 1.2rem; /* Adjust text size */
  font-weight: bold;
  border: 3px solid transparent;
  border-image: linear-gradient(45deg, red, blue, green, yellow) 1;
  border-radius: 5px;
  background-color: #f9f9f9;
  transition: transform 0.3s ease, border-color 0.3s ease;
}

.brand-box:hover {
  transform: scale(1.1);
  border-image: linear-gradient(45deg, yellow, green, blue, red) 1;
}

/* Animation for the border color */
.brand-box {
  animation: borderColorChange 5s infinite;
}

@keyframes borderColorChange {
  0% {
    border-image-source: linear-gradient(45deg, red, blue, green, yellow);
  }
  50% {
    border-image-source: linear-gradient(45deg, yellow, green, blue, red);
  }
  100% {
    border-image-source: linear-gradient(45deg, red, blue, green, yellow);
  }
}


/* Services Section */
.services-section {
  background-color: #f8f9fa;
  padding: 60px 0;
}

.service-card {
  border: none;
  transition: transform 0.3s;
}

.service-card:hover {
  transform: scale(1.05);
}

/* Features Section */
.features-section {
  background-color: #fff;
  padding: 60px 0;
}

.feature-card {
  text-align: center;
}

.feature-title {
  font-size: 1.5rem;
  margin-top: 20px;
  color: #333;
}

.feature-description {
  color: #666;
}

/* Courses Section */
.courses-section {
  background-color: #f8f9fa;
  padding: 60px 0;
}

.course-card {
  text-align: center;
}

.course-title {
  margin-top: 20px;
  font-size: 1.2rem;
  color: #007bff;
}

/* Success Stories Section */
.success-stories-section {
  background-color: #fff;
  padding: 60px 0;
}

.success-title {
  margin-top: 20px;
  font-size: 1.3rem;
  color: #007bff;
}

.success-description {
  color: #666;
}

/* Call to Action Section */
.call-to-action-section {
  background-color: #007bff;
  color: #fff;
  padding: 60px 0;
  text-align: center;
}

.cta-title {
  font-size: 2rem;
  margin-bottom: 20px;
}

.cta-subtitle {
  font-size: 1.25rem;
  margin-bottom: 30px;
}

.cta-button {
  background-color: #fff;
  color: #007bff;
  padding: 10px 30px;
  font-size: 1.1rem;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.cta-button:hover {
  background-color: #0056b3;
  color: #fff;
}

/* Modal */
.modal-content {
  text-align: center;
  padding: 40px;
}

.modal-title {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .hero-section {
    padding: 60px 15px; /* Adjust padding for mobile screens */
    min-height: 50vh; /* Adjust height for smaller devices */
    height: 50vh; /* Add specific height */
    background-size: contain; /* Show full background image */
    background-position: center center; /* Center the image */
    background-repeat: no-repeat; /* Prevent background repeat */
    background-attachment: scroll; /* Prevent fixed background on mobile */
  }

  .hero-title {
    font-size: 2.5rem; /* Smaller font size for mobile */
  }

  .hero-subtitle {
    font-size: 1rem; /* Smaller font size for mobile */
  }

  .cta-button {
    padding: 8px 20px;
    font-size: 1rem; /* Smaller font size for button */
    width: 80%; /* Button width adjusted for smaller screens */
  }
}

@media (max-width: 768px) {
  .hero-section {


    height: auto; /* Add specific height */
    background-size: contain; /* Show full background image */
    background-position: center center; /* Center the image */
    background-repeat: no-repeat; /* Prevent background repeat */
    background-attachment: scroll; /* Prevent fixed background on mobile */
  }

  .hero-title {
    font-size: 2rem; /* Even smaller font size */
  }

  .hero-subtitle {
    font-size: 0.9rem; /* Smaller font size */
  }

  .cta-button {
    padding: 6px 15px;
    font-size: 0.9rem; /* Even smaller font size for button */
    width: 90%; /* Button takes almost full width */
  }

  .brand-logos {
    padding: 30px 0;
  }

  .brand-logo {
    max-height: 40px; /* Smaller logo size */
  }

  .features-section {
    padding: 30px 0;
  }

  .feature-title {
    font-size: 1.2rem; /* Smaller font size */
  }

  .feature-description {
    font-size: 0.9rem; /* Smaller font size */
  }

  .courses-section {
    padding: 30px 0;
  }

  .course-title {
    font-size: 1rem; /* Smaller font size */
  }

  .success-stories-section {
    padding: 30px 0;
  }

  .success-title {
    font-size: 1.1rem; /* Smaller font size */
  }

  .success-description {
    font-size: 0.9rem; /* Smaller font size */
  }

  .call-to-action-section {
    padding: 30px 0;
  }

  .cta-title {
    font-size: 1.3rem; /* Smaller font size */
  }

  .cta-subtitle {
    font-size: 0.9rem; /* Smaller font size */
  }
}


.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cta-button {
  margin-top: 20px; /* Optional: Add some spacing between the button and other content */
}


.call-to-action-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh; /* Full viewport height to ensure vertical centering */
  padding: 20px; /* Optional: Add padding for better layout */
  background-color: #f8f9fa; /* Optional: Add a background color for better visibility */
}

.cta-title {
  margin-bottom: 20px; /* Adds spacing between the title and button */
  font-size: 24px; /* Optional: Adjust the font size for visibility */
}

.cta-button {
  padding: 10px 20px; /* Customize the button size */
  font-size: 18px; /* Optional: Adjust button text size */
}


.rounded-image {
  width: 150px;  /* Adjust size as needed */
  height: 150px; /* Keep width and height the same for perfect circle */
  border-radius: 50%;
  object-fit: cover;
  display: block;
  margin: 0 auto 15px; /* Center the images */
}

