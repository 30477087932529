/* FullWidthSlider.css */

.slider-container {
    width: 100%;
    height: auto; /* Use 'auto' instead of '100vh' */
    max-height: 100vh; /* Optional: limit height */
    overflow: hidden; /* Prevent overflow causing extra space */
    margin: 0;
    padding: 0;
}
.fullwidth-slider-container {
    margin: 0;
    padding: 0;
    overflow: hidden; /* Prevent extra space */
    position: relative; /* Ensure proper positioning */
}

.slide img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.slick-dots li button:before {
    color: white; /* Customize dot color */
}

.slick-prev:before,
.slick-next:before {
    color: white; /* Customize arrow color */
}
/* FullWidthSlider.css */

/* Existing styles */

@media (max-width: 768px) {
    .slider-container {
        height: 80vh; /* Slightly reduce height for smaller screens */
    }

    .slide img {
        height: 80vh; /* Adjust the height of images */
    }

    button {
        padding: 5px; /* Smaller buttons for mobile screens */
    }
}

@media (max-width: 480px) {
    .slider-container {
        height: 40vh; /* Further reduce height for very small screens */
    }

    .slide img {
        height: 60vh; /* Adjust the height of images */
        object-fit: cover;
    }

    button {
        padding: 2px; /* Smaller buttons for small screens */
    }
}












.slider-container {
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.slide {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh; /* Adjust height as needed */
}

.slider-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container */
}

.text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 10; /* Ensure it appears above the image */
}

.animated-text {
    font-size: 3rem; /* Adjust font size as needed */
    font-family: Arial, sans-serif;
    font-weight: bold;
    margin: 0;
    line-height: 1.5;
    color: #ffffff; /* Fallback color */
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Add a shadow for better readability */
}



body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    height: 100vh;
}

.header-text {
    margin-bottom: 20px;
    text-align: center;
    z-index: 10;
    color: white;
}
.slider-container {
    flex-grow: 1; /* Let the slider take the remaining space */
    width: 100%;
    height: auto;
}


footer {
    margin: 0;
    padding: 0;
    width: 100%;
    position: relative;
}
