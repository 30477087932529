/* Footer.css */

/* General Footer Styles */
.footer {
    background-color: #343a40;
    color: #fff;
    padding: 40px 0;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  /* Footer Sections */
  .footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .footer h5 {
    font-size: 1.25rem;
    margin-bottom: 20px;
  }
  
  .footer p {
    font-size: 0.875rem;
    margin-bottom: 10px;
    line-height: 1.6;
  }
  
  .footer a {
    color: #adb5bd;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer a:hover {
    color: #ffffff;
    text-decoration: underline;
  }
  
  .footer .social-links {
    margin: 20px 0;
  }
  
  .footer .social-links a {
    display: inline-block;
    margin: 0 10px;
    font-size: 1.5rem;
    color: #adb5bd;
    transition: color 0.3s ease;
  }
  
  .footer .social-links a:hover {
    color: #ffffff;
  }
  
  .footer .text-center {
    margin-top: 20px;
    font-size: 0.875rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer .container {
      flex-direction: column;
      align-items: center;
    }
  
    .footer h5 {
      font-size: 1.1rem;
    }
  
    .footer p {
      font-size: 0.75rem;
    }
  
    .footer .social-links a {
      font-size: 1.25rem;
    }
  }
