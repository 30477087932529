body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f7f7f7; /* Unified background color */
  color: #333;
}

.tp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f7f7f7; /* Same as the body background color */
  position: relative; /* Needed for the background element */
}

.tp-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f7f7f7; /* Unified background color */
  z-index: -1;
}

.tp-message-container {
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 700px;
  margin: auto;
  background-color: transparent; /* Remove background to inherit the page color */
}

.tp-congrats-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #27ae60;
}

.tp-congrats-text {
  font-size: 1.5rem;
  margin-bottom: 30px;
  color: #444;
}

.tp-collect-btn {
  background-color: #27ae60;
  color: white;
  padding: 15px 30px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tp-collect-btn:hover {
  background-color: #219150;
  transform: scale(1.05);
}   
