.video-wrapper {
  width: 100%; /* Adjust to the desired width */
  max-width: 800px; /* Example maximum width */
  height: 450px; /* Fixed height */
  overflow: hidden;
  background-color: #000;
}

.resource-video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures it fills the area while maintaining proportions */
}














.resources-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Flexible grid */
  gap: 20px;
  max-width: 100%;
}









@media (max-width: 768px) {
  .resource-card {
    max-width: 100%; /* Full width on small screens */
  }
}

@media (max-width: 480px) {
  .resources-container {
    padding: 10px; /* Add some spacing for better appearance */
  }
}





.resource-card {
  max-width: 300px; /* Adjust to fit your layout */
  overflow: hidden; /* Prevent content from spilling out */
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.resource-card img, 
.resource-card video {
  max-width: 100%; /* Prevent images/videos from stretching */
  height: auto;
  border-radius: 5px;
}

.resource-card h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}
