/* LP.css */

body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f7f7f7;
    color: #333;
  }
  
  .lp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }
  
  .lp-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
 
    z-index: -1;
  }
  
  .lp-logo-section {
    margin-top: 50px;
  }
  
  .lp-logo {
    width: 150px;
    height: auto;
  }
  
  .lp-title {
    font-size: 2rem;
    margin-top: 10px;
    color: #2c3e50;
  }
  
  .lp-promo {
    font-size: 1.2rem;
    margin: 20px 0;
    color: #444;
  }
  
  .lp-highlight {
    color: #27ae60;
    font-weight: bold;
  }
  
  .lp-download-btn {
    background-color: #27ae60;
    color: white;
    padding: 15px 30px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .lp-download-btn:hover {
    background-color: #219150;
    transform: scale(1.05);
  }
  
  .lp-promo-image-container {
    margin: 20px 0;
  }
  
  .lp-promo-image {
    width: 300px;
    height: auto;
    animation: float 3s ease-in-out infinite;
  }
  
  @keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  .lp-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 90%;
    max-width: 400px;
  }
  
  .lp-modal-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .lp-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .lp-submit-btn {
    background-color: #27ae60;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .lp-submit-btn:hover {
    background-color: #219150;
    transform: scale(1.05);
  }
  
  .lp-footer {
    margin-top: 30px;
    font-size: 0.9rem;
    color: #666;
    text-align: center;
  }
  